import { DropdownItemProps } from 'semantic-ui-react'
import { Params } from 'react-router'
import React from 'react'
import dayjs from 'dayjs'
import debug from 'debug'
import { decode as heDecode } from 'html-entities'
import { isBirthdayThisMonth } from 'AppSrc/metadata/helpers'
import { isCheckedIn } from 'AppSrc/checkin/helpers'
import { itemsSelected } from 'AppSrc/refresh/helpers'
// eslint-disable-next-line import/extensions,import/no-unresolved
import { userRoleEnum } from 'ServerSrc/enums'
import config from 'Config/config'

debug.enable('registrants/helpers:*')
// const log = debug('registrants/helpers:log')
// const info = debug('registrants/helpers:info')
const error = debug('registrants/helpers:error')

// Registrant item keys whose order is not completed
declare global {
  interface Array<T> {
    filterItemKeysByOrderStatus(
      this: Array<T>,
      regItems: RegistrantItemsType,
      status?: Array<string>
    ): Array<T>
  }
}
// eslint-disable-next-line no-extend-native
Array.prototype.filterItemKeysByOrderStatus = function filterItemKeysByOrderStatus(
  this,
  regItems: RegistrantItemsType,
  status = ['completed']
) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return this.filter((key: string) => status.indexOf(regItems[key].status!) === -1)
}

type ProductInfoType = {
  price: number
  quantity: number
  text: string
  name: string
}

type ProductsInfoType = { [id: string]: ProductInfoType }

export const nameCapitalize = (name: string) =>
  name
    .split(' ')
    .map(word => {
      if (word.match(/^(de|von)$/i)) return word.toLowerCase()
      if (word.toUpperCase() === word || word.toLowerCase() === word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      }
      return word
    })
    .map(word => word.replace(/^(mc|O')(.)/i, (s, m, l) => `${m}${l.toUpperCase()}`))
    .map(word => word.replace(/^(I+|IV|VI+|IX),?$/i, s => s.toUpperCase()))
    .join(' ')

export const getRegistrantNames = (reg: RegistrantType, regItemKey?: string) => {
  const names: Array<string> = []
  const keys = regItemKey ? [regItemKey] : Object.keys(reg.items || {})

  // log('getRegistrantNames', regItemKey)
  keys
    .map(key => reg.items[key])
    .forEach(item => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      let candidate = nameCapitalize(`${item!.lastname?.trim()}, ${item!.firstname?.trim()}`)
      // log('candidate', candidate)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (!item!.lastname) candidate = `<missing lastname>${candidate}`
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (!item!.firstname) candidate += '<missing firstname>'
      if (names.indexOf(candidate) === -1) names.push(candidate)
    })

  // make sure username appears first if defined
  if (reg.firstname && reg.lastname) {
    const username = nameCapitalize(`${reg.lastname.trim()}, ${reg.firstname.trim()}`)
    const index = names.indexOf(username)
    if (index > 0) {
      names.splice(index, 1)
      names.unshift(username)
    }
  }

  return names
}

const itemMetaDataSort = (a: RegistrantItemMetaDataType, b: RegistrantItemMetaDataType) => {
  if (!`${a.key} ${b.key}`.match(/track|placement|role/i)) return 0
  if (a.key.match(/track|placement/i)) return -1
  return 1
}

export const getRegistrantItemAttrs = (
  reg: RegistrantType,
  regItemKey: string,
  filterAttrs?: Array<string>
) => {
  const itemMetaData: Array<RegistrantItemMetaDataType> = reg.items[regItemKey].item_meta_data
  const itemName = reg.items[regItemKey].item_name
  const attrs: {
    track?: Array<string>
    placement?: Array<string>
    role?: Array<string>
    days?: Array<string>
    size?: Array<string>
    style?: Array<string>
    'series-number-of-weeks'?: string[]
    'drop-in-dance-party-type'?: string[]
  } = {}

  const attributes = filterAttrs ?? ['role', 'track', 'placement', 'days', 'size', 'style']
  const regex = new RegExp(`^(${attributes.join('|')})$`)

  // Order should be track|placement - role
  // (sort happens in place, so we make a shallow copy of the array)
  ;[...itemMetaData].sort(itemMetaDataSort).forEach(meta => {
    const matches = meta.key.replace(/^pa_/, '').match(regex)
    if (matches && (!filterAttrs || filterAttrs.indexOf(meta.key) !== -1)) {
      const key = matches[1] as keyof typeof attrs
      attrs[key] || (attrs[key] = [])
      const attrValue = attrs[key]
      if (attrValue && !attrValue.length && typeof meta.value === 'string') {
        let value = meta.value
        if (key === 'series-number-of-weeks') {
          value = value.match(/\d+-week-series/) ? 'series' : 'drop-in'
        }
        attrValue.push(value.length <= 3 ? value : nameCapitalize(value))
      }
    }
  })

  if (attrs.track && attrs.track[0].match(/the.music.and.you/i)) {
    attrs.track[0] = 'The Music and You Track'
    delete attrs.placement
  }
  if (attrs.track && attrs.track[0].match(/beginner|newcomer/i)) {
    attrs.track[0] = 'Newcomers Track'
    delete attrs.placement
  }
  if (attrs.track && attrs.track[0].match(/leveled.tracks/i)) {
    delete attrs.track
    if (attrs.placement && attrs.placement[0].match(/M&Y/i))
      attrs.placement[0] = 'Incorrect Placement Track'
  }

  if (attrs.role && attrs.role[0].match(/any/i)) {
    delete attrs.role
  }
  if (!attrs.role && itemName.match(/SATS /)) return attrs

  if (!attrs.role && itemName.match(/class/i) && !itemName.match(/taster|dance/i)) {
    // FIXME? how to identify solo classes
    attrs.role = ['Solo']
  }

  if (!attrs.role && itemName.match(/\((leader|follower|solo)\)/i)) {
    attrs.role = []
    attrs.role.push(nameCapitalize(itemName.replace(/^.*\((leader|follower|solo)\).*$/i, '$1')))
  }

  // log(itemMetaData, attrs)

  return attrs
}

export const getRegistrantItemKeysByName = (
  regItems: RegistrantItemsType,
  reg: RegistrantType,
  name: string
) => Object.keys(regItems).filter(key => name === getRegistrantNames(reg, key).toString())

export const getRegistrantRegItemsByName = (
  regItems: RegistrantItemsType,
  reg: RegistrantType,
  name: string
): RegistrantItemsType =>
  getRegistrantItemKeysByName(regItems, reg, name).reduce((acc, key) => {
    acc[key] = regItems[key]
    return acc
  }, {} as RegistrantItemsType)

export const getRegistrantCheckinsByName = (
  regCheckins: CheckinsType,
  regItems: RegistrantItemsType,
  reg: RegistrantType,
  name: string
): CheckinsType =>
  getRegistrantItemKeysByName(regItems, reg, name).reduce((acc, key) => {
    // log('getRegistrantCheckinsByName', regItems, regCheckins, key, name)
    const checkinKey = regItems[key].checkin_key
    if (checkinKey !== undefined && objHasKey(regCheckins, checkinKey)) {
      acc[checkinKey] = regCheckins[checkinKey]
    }
    return acc
  }, {} as CheckinsType)

export const getCheckinWeek = (checkinKey: CheckinKeyType) => {
  const match = checkinKey ? checkinKey.match(/^.* week(\d+)$/) : []
  if (match && match.length) {
    return `Week ${match[1]}`
  }
  return ''
}

const getProductIdsIfEvent = (itemSlug: string, allItems: DbItemsType) => {
  const pids = {} as { [id: string]: boolean }

  Object.entries(allItems).forEach(([itemKey, item]) => {
    if (config.hasEvents) {
      if (itemSlug === itemKey && item.product?.id && item.event) {
        pids[item.product.id] = true
      } else if (item.product && itemSlug === item.product.event_slug) {
        /** @deprecated: product.event_slug is deprecated */
        // FIXME: not sure if this case is still applicable?
        pids[item.product.id] = true
      }
    } else if (itemSlug === itemKey && item.product?.id) {
      // for SATS
      pids[item.product.id] = true
    }
  })

  return pids
}

// export const getItemAttributes = (itemIdSlug: string, items: ItemsType) => {
//   const itemsById = {}
//   items.forEach(item => {
//     // log('item', item, Object.keys(item)[0], itemIdSlug)
//     itemsById[Object.keys(item)[0]] = item[Object.keys(item)[0]]
//   })
//
//   const itemValue = itemsById[itemIdSlug]
//
//   if (itemValue && itemValue.product && itemValue.product.attributes) {
//     const attributes = {}
//     itemValue.product.attributes.forEach(attribute => {
//       attributes[attribute.name.toLowerCase()] = attribute.options.map(attr => nameCapitalize(attr))
//     })
//     return attributes
//   }
//
//   return []
// }

export const getFilteredItemKeys = (
  reg: RegistrantType,
  productIds: { [id: string]: number },
  settings: settingsType
) =>
  Object.keys(reg.items)
    .filter(key =>
      Object.keys(productIds).length
        ? productIds[reg.items[key].item_product_id]
        : reg.items[key].valid_product
    )
    .filter(key => {
      // filter out cancelled orders if hideOrdersWhenCancelled is true
      if (settings.uiRegistrants.hideOrdersWhenCancelled) {
        const orderId = key.replace(/^([^-]+)-.*$/, '$1')
        if (reg.order_status[orderId] === 'cancelled') {
          return false
        }
      }
      // filter out refunded orders if hideOrdersWhenRefunded is true
      if (settings.uiRegistrants.hideOrdersWhenRefunded) {
        const orderId = key.replace(/^([^-]+)-.*$/, '$1')
        if (reg.order_status[orderId] === 'refunded') {
          return false
        }
      }
      return true
    })
    // filter out deselected items so that we do not check in for them
    .filter(
      key => !(settings.itemsDeselected && settings.itemsDeselected[reg.items[key].item_product_id])
    )
    // filter out bundled products based on settings
    .filter(key => settings.uiItems.itemsPartOfBundle || !itemIsBundled(reg.items[key]))

export const getFilteredItems = (
  reg: RegistrantType,
  productIds: { [id: string]: number },
  settings: settingsType
) => {
  const obj = {} as RegistrantType['items']
  getFilteredItemKeys(reg, productIds, settings).forEach(key => {
    obj[key] = reg.items[key]
  })
  return obj
}

export const getFilteredReg = (
  reg: RegistrantType,
  productIds: { [id: string]: number },
  settings: settingsType
) => {
  const filteredItems = getFilteredItems(reg, productIds, settings)
  if (Object.keys(reg.items).length !== Object.keys(filteredItems).length) {
    return { ...reg, items: filteredItems } // shallow copy is enough
  }
  return reg
}

export const getFilteredRegByItemIds = (
  reg: RegistrantType,
  itemIds: { [id: string]: number },
  settings: settingsType
) => {
  const filteredItems = {} as RegistrantType['items']
  Object.keys(reg.items)
    .filter(itemId => objHasKey(itemIds, itemId))
    .forEach(itemId => {
      filteredItems[itemId] = reg.items[itemId]
    })

  if (Object.keys(reg.items).length !== Object.keys(filteredItems).length) {
    return { ...reg, items: filteredItems } // shallow copy is enough
  }
  return reg
}

const registrantSearch = (
  reg: RegistrantType,
  search: string,
  user: DbUserType,
  settings: settingsType
) => {
  if (!search) return true

  // add registrant names to search
  const words: Array<string> = []
  Object.keys(reg.items).forEach(key => {
    const lastname = reg.items[key].lastname
    const firstname = reg.items[key].firstname
    if (lastname && words.indexOf(lastname) === -1) words.push(lastname)
    if (firstname && words.indexOf(firstname) === -1) words.push(firstname)
  })
  if (reg.lastname && words.indexOf(reg.lastname) === -1) words.push(reg.lastname)
  if (reg.firstname && words.indexOf(reg.firstname) === -1) words.push(reg.firstname)
  if (words.length > 2) {
    words.push('multi') // mark card as 'multi' if it has more than one registrant name
  }

  Object.keys(reg.order_status).forEach(orderId => {
    if (words.indexOf(orderId) === -1) words.push(`#${orderId}`)
  })

  // add 'order'/'note' to search if an order note is found
  const orderNotesKeys = Object.keys(reg.order_notes)
  // adding order note content, could slow things down
  orderNotesKeys.forEach(orderId => {
    Object.values(reg.order_notes[orderId]).forEach((note: string) => {
      note.split(' ').forEach(word => {
        words.push(word)
      })
    })
  })
  if (orderNotesKeys.length) {
    words.push('order')
    words.push('notes')
  }

  const orderRefundsKeys = Object.keys(reg.refunds || {})
  orderRefundsKeys.forEach(orderId => {
    Object.values(reg.refunds[orderId]).forEach((refund: wcOrderRefundType) => {
      ;(refund.reason || '').split(' ').forEach((word: string) => {
        words.push(word)
      })
    })
  })
  if (orderRefundsKeys.length) {
    words.push('refunds')
  }

  Object.keys(reg.items).forEach(key => {
    const itemName = stripItemName(reg.items[key].item_name, {
      keepAttributes: true,
    })
    if (words.indexOf(itemName) === -1) words.push(itemName)

    const metaData = reg.items[key].item_meta_data
    Object.entries(metaData).forEach(([_k, entry]) => {
      const metaKey = entry.key
      if (metaKey && !metaKey.match(/^_|size/)) {
        const metaDataValue = entry.value
        if (typeof metaDataValue === 'string' && words.indexOf(metaDataValue) === -1)
          words.push(metaDataValue)
      }
    })
  })

  // add non-completed order status to search
  Object.keys(reg.items)
    .filterItemKeysByOrderStatus(reg.items)
    .forEach(key => {
      if (words.indexOf('order') === -1) words.push('order')
      if (words.indexOf('status') === -1) words.push('status')
      if (words.indexOf(reg.items[key].status)) words.push(reg.items[key].status)
    })

  // customer note
  Object.keys(reg.customer_note).forEach(key => {
    if (reg.customer_note[key].length) {
      if (words.indexOf('customer') === -1) words.push('customer')
      if (words.indexOf('note') === -1) words.push('note')
    }
    if (reg.customer_note[key].match(/manually entered/i)) {
      // log('note', reg.firstname, reg)
      if (words.indexOf('manual') === -1) words.push('manual')
      if (words.indexOf('entered') === -1) words.push('entered')
      if (words.indexOf('front') === -1) words.push('front')
      if (words.indexOf('desk') === -1) words.push('desk')
    }
    if (reg.customer_note[key].match(/volunteer/i)) {
      if (words.indexOf('volunteer') === -1) words.push('volunteer')
    }
  })

  // customer birthday verification
  if (user?.birthday_needs_verification) {
    if (words.indexOf('birthday') === -1) words.push('birthday')
  }
  if (user?.meta?.birthday_month_year && isBirthdayThisMonth(user.meta.birthday_month_year)) {
    if (words.indexOf('birthday') === -1) words.push('birthday')
  }

  // customer vaccination verification
  if (!settings.uiRegistrants.hideVaccinationStatus && user?.vaccination_needs_verification) {
    if (words.indexOf('vaccination') === -1) words.push('vaccination')
  }

  // run search using regex
  const searchExpr = getQuery(search.replace(/^\s+/, '').replace(/\s+$/, ''))
  let match = 0
  searchExpr.forEach(expr => {
    words.forEach(word => {
      try {
        if (word.match(new RegExp(expr, 'i'))) match += 1
      } catch (err) {
        // not catching regexp errors
      }
    })
  })

  return match >= searchExpr.length
}

export const getQuery = (query: string) => {
  const re = /"([^"]+)"|\S+/g
  const res = []
  let m
  while ((m = re.exec(query)) !== null) {
    res.push(m[1] || m[0])
  }
  return res
}

export const getRegistrantsSubheader = (
  checkedInStatus: boolean | 'any',
  registrantCounts: RegistrantCountsType,
  searchString: string,
  itemId: string
) => {
  const key = checkedInStatus === 'any' ? 'any' : checkedInStatus ? 'checkedIn' : 'notCheckedIn' // eslint-disable-line no-nested-ternary
  const count = registrantCounts[key]
  const attrsKeys = Object.keys(count.attrs || {})

  const allAttrs = attrsKeys
    .map(
      attrKey =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        `${count.attrs![attrKey]} ${attrKey}${itemId ? '' : ' spot'}${
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          count.attrs![attrKey] > 1 ? 's' : ''
        }`.replace(/ss$/, 's') // fix up `seriess`
    )
    .join(', ')

  return (
    (searchString ? 'found ' : '') + // eslint-disable-line prefer-template
    `${count.main} ` +
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    `registrant${itemId ? '' : ' item'}${count.main! > 1 ? 's' : ''}` +
    (allAttrs ? ` (${allAttrs})` : '')
  )
}

export const getRegistrantsSubheaderNoneFound = (
  checkedInStatus: boolean | 'any',
  registrantCounts: RegistrantCountsType,
  searchString: string,
  itemId: string
) =>
  getRegistrantsSubheader(checkedInStatus, registrantCounts, searchString, itemId).match(
    /(^|\s+)0 registrant/
  )

const arrayToObject = <T extends string | number | symbol>(arr: T | T[]) => {
  const array = Array.isArray(arr) ? arr : [arr]
  return array.reduce((acc, item) => {
    acc[item] = 1
    return acc
  }, {} as Record<T, number>)
}

// Filter registrants
//   if itemId is provided, make sure the registrant has it
//   filter out invalid products
//   filter based on search string
//   filter canceled orders if setting is set
export const getFilteredRegistrants = (
  registrants: RegistrantsType,
  itemId: Array<string>,
  searchString: string,
  users: DbUsersType,
  settings: settingsType
) =>
  Object.values(registrants)
    .map(reg => {
      if (itemId) {
        // check if registrant has the correct item
        const filteredReg = getFilteredReg(reg, arrayToObject(itemId), settings)
        // if not, return filtered registrant (it will be filtered out below)
        // otherwise, return registrant with non-valid items filtered out
        return !Object.keys(filteredReg.items).length
          ? filteredReg
          : getFilteredReg(reg, {}, settings)
      }
      return reg
    })
    .filter(reg => Object.keys(reg.items).length)
    .map(reg => {
      if (!searchString) return reg

      const names = getRegistrantNames(reg)
      if (names.length > 1) {
        let cnt = 0
        let filteredItemsIds: Array<string> = []
        for (const name of names) {
          const itemIds = getRegistrantItemKeysByName(reg.items, reg, name)
          const filteredReg = getFilteredRegByItemIds(reg, arrayToObject(itemIds), settings)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const filteredRegSearch = registrantSearch(
            filteredReg,
            searchString,
            users[reg.id!],
            settings
          )
          if (filteredRegSearch) {
            cnt += 1
            filteredItemsIds = filteredItemsIds.concat(itemIds)
          }
        }
        if (cnt !== names.length) {
          const filteredReg = getFilteredRegByItemIds(
            reg,
            arrayToObject(filteredItemsIds),
            settings
          )
          return filteredReg
        }
      }

      return reg
    })
    .filter(reg => registrantSearch(reg, searchString, users[reg.id], settings))

export const itemIsBundle = (item: RegistrantItemType) =>
  item.item_meta_data && item.item_meta_data.filter(meta => meta.key === '_bundled_items').length

export const itemIsBundled = (item: RegistrantItemType) =>
  item.item_meta_data && item.item_meta_data.filter(meta => meta.key === '_bundled_by').length

export const calculateRegistrantCounts = (
  registrants: RegistrantsType,
  itemId: Array<string>,
  searchString: string,
  users: DbUsersType,
  settings: settingsType,
  filterAttrs: string[] = ['pa_role']
) => {
  // count all things and count per attribute set
  const registrantCounts: RegistrantCountsType = {
    notCheckedIn: { main: 0, attrs: {} },
    checkedIn: { main: 0, attrs: {} },
    any: { main: 0, attrs: {} },
  }
  const any = registrantCounts.any

  getFilteredRegistrants(registrants, itemId, searchString, users, settings).forEach(reg => {
    let count: RegistrantCountGroupType = {}
    if (isCheckedIn(reg.checkins, reg.items)) {
      count = registrantCounts.checkedIn
    } else {
      count = registrantCounts.notCheckedIn
    }

    const itemKeys = Object.keys(reg.items)
    const filteredItemKeys =
      itemId && itemId.length ? getFilteredItemKeys(reg, arrayToObject(itemId), settings) : itemKeys
    if (!filteredItemKeys.length && itemKeys.length) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      count.main! += 1
    }
    filteredItemKeys
      .filter(regItemKey => (itemId && itemId.length ? true : !itemIsBundle(reg.items[regItemKey])))
      .forEach(regItemKey => {
        const attrs = getRegistrantItemAttrs(reg, regItemKey, filterAttrs)
        // log('calculateRegistrantCounts', attrs)
        Object.entries(attrs).forEach(([_key, attr]) => {
          const attrVal = attr
            .map((val: string) => (val.length > 1 ? val.toLowerCase() : val))
            .join('/')

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          count.attrs![attrVal] || (count.attrs![attrVal] = 0)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          count.attrs![attrVal] += reg.items[regItemKey].item_quantity
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          any.attrs![attrVal] || (any.attrs![attrVal] = 0)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          any.attrs![attrVal] += reg.items[regItemKey].item_quantity
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          count.main! += reg.items[regItemKey].item_quantity
          // log(reg.items[regItemKey].firstname, reg.items[regItemKey].lastname, role)
        })
        if (!Object.keys(attrs).length) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          count.main! += reg.items[regItemKey].item_quantity
        }
      })
  })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  any.main = registrantCounts.checkedIn.main! + registrantCounts.notCheckedIn.main!

  return registrantCounts
}

// get item title
const getItemTitle = (item: DbItemType | undefined, settings: settingsType) => {
  // log('getItemTitle', item)
  const productOrEvent = settings.uiItems?.itemsWithoutEvents

  return productOrEvent ? item?.product?.name : item?.event?.title || item?.product?.name
}

// const getItemSlug = (itemUri: string, items: DbItemsType) =>
//   Object.keys(items)
//     .map(key => ({
//       key,
//       item: items[key],
//     }))
//     .filter(({ key, item }) => item.event?.slug === itemUri || item.product?.slug === itemUri)
//     .map(({ key }) => key)
//     .join(' ')

export const stripItemName = (
  name: string,
  opts?: {
    keepAttributes?: boolean
    removeContentInParenthesis?: boolean
  }
) => {
  const { keepAttributes = false, removeContentInParenthesis = false } = opts || {}

  // remove anything inside parenthesis - used by SATS
  const strippedName = removeContentInParenthesis ? name.replace(/\s*\([^)]*\)/, '') : name

  if (keepAttributes) return strippedName

  return (
    strippedName
      .replace(/(\s+(.)(\s+)?|,\s+)(leader|follower|solo).*$/i, '')
      .replace(/(\s+Class Series).*/i, '')
      .replace(/(\s+Series).*/i, '')
      // .replace(/\s+-\s+(Full Weekend|Sunday|Women|Men|Weekend|Saturday \+ Sunday|Saturday|Sunday)/i, '')
      // .replace(/(\s+-|,)\s+(XS|S|M|L|XL|2XL)$/, '')
      .replace(/\s+-.*$/, '')
  )
}

export const getItemAttrsClassName = (reg: RegistrantType, key: string) =>
  stripItemName(reg.items[key].item_name, { removeContentInParenthesis: true })
    .concat(
      ' ',
      Object.values(getRegistrantItemAttrs(reg, key))
        .map(attrVal => `${attrVal.join('-').replace(/\s+/g, '-')}`)
        .join('–')
    )
    .replace(/\s+/g, '-')
    .toLowerCase()

export const getMatchingUniqueOrderItemKeys = (
  regItems: RegistrantItemsType,
  reg: RegistrantType,
  name: string
) => {
  const matchingItemKeys = getRegistrantItemKeysByName(
    regItems,
    reg,
    name
  ).filterItemKeysByOrderStatus(regItems)
  if (!matchingItemKeys.length) {
    return []
  }

  const matchingUniqueOrderItemKeys = matchingItemKeys
    .reduce(
      (acc: [obj: { [id: string]: number }, arr: Array<string>], key) => {
        const orderId = regItems[key].id
        if (!objHasKey(acc[0], orderId)) {
          // if (!(orderId in acc[0])) {
          acc[0][orderId] = 1 // to avoid duplicates
          acc[1].push(key)
        }
        return acc
      },
      [{}, []]
    )
    .reduce((acc, val, idx) => (idx === 1 ? val : acc), []) // get the matching keys

  return matchingUniqueOrderItemKeys as Array<string>
}

export const arrayFlatten = <T,>(arr: Array<T>): Array<T> =>
  arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? arrayFlatten(val) : val), [] as T[])

export const getRegistrantOrderIdsByName = (
  regItems: RegistrantItemsType,
  reg: RegistrantType,
  name: string
) => {
  const orderIds = {} as { [id: string]: 1 }
  // log('getRegistrantOrderIdsByName regItems', regItems)
  getRegistrantItemKeysByName(regItems, reg, name).forEach(itemKey => {
    const item = regItems[itemKey]
    orderIds[item.id] = 1
  })
  return Object.keys(orderIds)
}

export const getValidProductIdsForCheckin = (allItems: DbItemsType, items: DbItemsType) => {
  let pids = {} as { [id: string]: boolean }
  Object.keys(items).forEach(slug => {
    pids = Object.assign(pids, getProductIdsIfEvent(slug, allItems))
  })
  return pids
}

export const getRegistrantDropdownValues = (
  regItems: RegistrantItemsType,
  reg: RegistrantType,
  name: string,
  orderId: string,
  create: boolean,
  dropDownValues: { [id: string]: Array<string> },
  allItems: DbItemsType
) => {
  let values: Array<{ key: string; text: string; value: string }> = []

  // log('getRegistrantDropdownValues', dropDownValues[orderId], regItems)
  if (dropDownValues[orderId]) {
    // log('getRegistrantDropdownValues getValuesProductsInfo', allItems)
    const { infoByValue } = getValuesProductsInfo(dropDownValues[orderId], allItems)
    values = values.concat(
      dropDownValues[orderId].map(key => ({
        key,
        text: infoByValue[key].text,
        value: key,
      }))
    )

    return values
  }

  // if (create) log('values', values)
  if (create) return values

  // log(regItems, reg, name)
  values = values.concat(
    getRegistrantItemKeysByName(regItems, reg, name)
      .filter(itemKey => !orderId || regItems[itemKey].id.toString() === orderId)
      .map(itemKey => {
        const item = regItems[itemKey]
        const quantity = `${item.item_quantity} x `
        const key = `${quantity}${(item.item_variation_id || item.item_product_id).toString()}`
        // 'values', { key, text: `${quantity}${item.item_name}`, value: key })
        return {
          key,
          text: `${quantity}${stripItemName(item.item_name, {
            keepAttributes: true,
          })}`,
          value: key,
        }
      })
  )

  // log('getRegistrantDropdownValues values', values)
  return values
}

export const getRegistrantDropdownOptions = (
  regItems: RegistrantItemsType,
  reg: RegistrantType,
  name: string,
  orderId: string,
  allItems: DbItemsType,
  items: DbItemsType,
  create: boolean,
  dropDownValues: { [id: string]: Array<string> }
) => {
  // get all valid product IDs
  const validProductIds = getValidProductIdsForCheckin(allItems, items)
  // Add registrant product IDs to the list of valid product IDs
  Object.values(regItems).forEach(regItemVal => {
    validProductIds[regItemVal.item_product_id] = true
  })
  const isValidProductId = (pid: string) => objHasKey(validProductIds, pid)

  const options = getRegistrantDropdownValues(
    regItems,
    reg,
    name,
    orderId,
    create,
    dropDownValues,
    allItems
  ) // get registrant values

  const allItemsProducts = Object.values(allItems)
    .filter(item => item.product)
    .map(item => item.product)
  // log('allItemsProducts', allItemsProducts)

  let counter = 0
  allItemsProducts
    .filter(product => product && isValidProductId(product.id))
    .forEach(product => {
      if (product?.product_variations && Object.keys(product.product_variations).length) {
        // log(product.name, product.product_variations)
        Object.keys(product.product_variations).forEach(variationId => {
          const id = variationId.toString()
          const key = product.sold_individually !== true ? `#${counter} - ${id}` : `1 x ${id}`
          // log('product variation key', product.product_variations[variationId], key)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const productName = product.product_variations![variationId].name
          const option = {
            key,
            text: stripItemName(productName, { keepAttributes: true }),
            value: key,
          }
          // log('options', JSON.stringify(options))
          // log('option', option, options.findIndex(opt => opt.key === key))
          if (options.findIndex(opt => opt.key === key) === -1) options.push(option)
        })
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const id = product!.id.toString()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const key = product!.sold_individually !== true ? `#${counter} - ${id}` : `1 x ${id}`
        // log('options', { key, text: product.name, value })
        const option = {
          key,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          text: stripItemName(product!.name, { keepAttributes: true }),
          value: key,
        }
        if (options.findIndex(opt => opt.key === key) === -1) options.push(option)
      }
      counter += 1
    })

  // log('options', options)
  return options
}

export const objHasKey = (obj: object, key: string | undefined) =>
  key !== undefined &&
  (Object.prototype.hasOwnProperty.call(obj, key) ||
    (typeof obj === 'object' && key in obj && Object.getPrototypeOf(obj) === null))

export const renderLabel = (
  label: DropdownItemProps,
  orderId: string,
  defaultValues: Array<string>
) => {
  // log('renderLabel', defaultValues, label)
  const existingValue =
    !defaultValues || !defaultValues.length || defaultValues.indexOf(label.value as string) !== -1
  return {
    color: existingValue ? undefined : 'green',
    content: label.text,
    // icon: 'check',
  }
}

export const itemsKeysSort = (regItems: RegistrantItemsType) => (a: string, b: string) => {
  if (regItems[a].item_name < regItems[b].item_name) return -1
  if (regItems[a].item_name > regItems[b].item_name) return 1
  return 0
}

export const getItemUri = (item: DbItemType) => item.slug

export const getItemInfo = ({
  items,
  allItems,
  settings,
  match,
}: {
  items: DbItemsType
  allItems: DbItemsType
  settings: settingsType
  match: { params: Params }
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const itemId = match.params.itemId!
  const itemSlug = itemId
  const itemTitle = (itemSlug && getItemTitle(items[itemSlug], settings)) || ''

  const productIdsIfEvent = getProductIdsIfEvent(itemSlug, allItems)
  // log('itemId', itemId, itemSlug, Object.keys(productIdsIfEvent).join(','))

  let itemIdArray: Array<string>

  if (!itemId) {
    // all registrants
    // skip deselected items
    const selectedItems = Object.values(allItems)
      .filter(itemsSelected(settings))
      .reduce((acc, item) => {
        acc[item.slug] = item
        return acc
      }, {} as DbItemsType)
    // log('selectedItems', selectedItems)
    const itemIdObj = getValidProductIdsForCheckin(selectedItems, items)
    itemIdArray = Object.keys(itemIdObj)
  } else if (
    itemId === Object.keys(productIdsIfEvent).join(',') ||
    !Object.keys(productIdsIfEvent).length
  ) {
    // needed by SATS
    // FIXME: not sure if this case is still applicable?
    itemIdArray = [itemId]
  } else {
    itemIdArray = Object.keys(productIdsIfEvent)
  }

  return { itemId, itemTitle, itemIdArray }
}

export const getRegistrantCountsGroups = ({
  registrants,
  itemIdArray,
  activeSearchString,
  users,
  settings,
  filterAttrs,
}: {
  registrants: RegistrantsType
  itemIdArray: Array<string>
  activeSearchString: string
  users: DbUsersType
  settings: settingsType
  filterAttrs?: string[]
}) => {
  // get registrants counts
  const registrantCounts = calculateRegistrantCounts(
    registrants,
    itemIdArray,
    activeSearchString,
    users,
    settings,
    filterAttrs
  )
  const registrantGroups: RegistrantGroupsType = []
  if (settings.uiRegistrants && settings.uiRegistrants.separateCheckedIn) {
    if (registrantCounts.notCheckedIn.main)
      registrantGroups.push({
        checkedInStatus: false,
        header: 'Registrants to check-in',
      })
    if (registrantCounts.checkedIn.main)
      registrantGroups.push({
        checkedInStatus: true,
        header: 'Checked-in registrants',
      })
  } else if (registrantCounts.any.main) {
    registrantGroups.push({ checkedInStatus: 'any', header: 'Registrants' })
  }
  // log('registrantCounts', registrantCounts)

  return { registrantCounts, registrantGroups }
}

export const newRegInfo: Partial<RegistrantType> = {
  id: 0,
  email: 'no email yet',
  avatar: 'https://secure.gravatar.com/avatar/08eb813fff818a069e7f798f5574a7c7?f=y&s=96&d=mm&r=g',
  items: {
    '999999-888888': {
      id: '999999',
      item_id: '888888',
      status: 'new',
      firstname: 'or pick',
      lastname: 'Enter name',
    } as RegistrantItemType,
  },
  order_status: { '999999': 'new' },
  order_extra: { '999999': {} },
  refundable: { '999999': 0 },
  cancellable: { '999999': 0 },
  refunds: {},
  order_notes: {},
  customer_note: {},
  total: { '999999': 0 },
  payment_method: { '999999': 'stripe' }, // used to be 'cod'
  discount_total: { '999999': 0 },
}

export const getRegInfo = (
  regId: number | string,
  locationIndex: number,
  newReg: Partial<RegistrantType> | null,
  items: DbItemsType,
  registrants: RegistrantsType,
  users: DbUsersType,
  { updateNewReg } = { updateNewReg: false }
) => {
  let reg: RegistrantType = {} as RegistrantType

  if (!newReg) {
    objHasKey(registrants, regId.toString()) && (reg = registrants[regId])
  } else if (!updateNewReg) {
    reg = newReg as RegistrantType
  } else {
    reg = JSON.parse(JSON.stringify(newRegInfo)) // deep copy

    if (objHasKey(users, regId.toString())) {
      reg.id = isNaN(Number(regId)) ? regId : Number(regId)
      reg.email = users[regId].email
      reg.avatar = users[regId].avatar
      Object.keys(reg.items).forEach(itemId => {
        reg.items[itemId].firstname = users[regId].firstname
        reg.items[itemId].lastname = users[regId].lastname
      })
    }
  }

  let regName = ''
  if (reg && Object.keys(items).length) {
    regName = getRegistrantNames(reg)
      .filter((name, index) => index === locationIndex)
      .map((name, index) => name)
      .toString()
  }

  const userRoles = getUserRoles(reg, users)

  return { reg, regName, userRoles }
}

export const regUserExists = (reg: RegistrantType, users: DbUsersType) =>
  !!reg.id && objHasKey(users, reg.id.toString())

export const getRegUser = (reg: RegistrantType, users: DbUsersType) =>
  regUserExists(reg, users) && users[reg.id]

export const getUserRoles = (
  reg: RegistrantType,
  users: DbUsersType
): Array<checkin.userRoleEnum> => (getRegUser(reg, users) || { roles: [] }).roles

const getProductOrVariationFromId = (id: string, items: DbItemsType) => {
  // id is a string
  for (const slug of Object.keys(items)) {
    const itemProduct = items[slug].product
    if (itemProduct) {
      if (id === itemProduct.id) return itemProduct
      const variations = itemProduct.product_variations || {}
      for (const pId of Object.keys(variations)) {
        if (id === pId) return variations[pId]
      }
    }
  }

  return null
}

const getProductFromId = (id: string, items: DbItemsType) => {
  // id is a string
  for (const slug of Object.keys(items)) {
    const itemProduct = items[slug].product
    if (itemProduct) {
      if (id === itemProduct.id) return itemProduct
      const variations = itemProduct.product_variations || {}
      for (const pId of Object.keys(variations)) {
        if (id === pId) return itemProduct
      }
    }
  }

  return null
}

type PricingTableType = {
  '(3|4|5|6|7|8|9|13|14)-week-series': {
    3: { default: number[]; '24__under'?: number[]; senior?: number[] }
    4: { default: number[]; '24__under'?: number[]; senior?: number[] }
    5: { default: number[]; '24__under'?: number[]; senior?: number[] }
    6: { default: number[]; '24__under'?: number[]; senior?: number[] }
    7: { default: number[]; '24__under'?: number[]; senior?: number[] }
    8: { default: number[]; '24__under'?: number[]; senior?: number[] }
    9: { default: number[]; '24__under'?: number[]; senior?: number[] }
    13: { default: number[]; '24__under'?: number[]; senior?: number[] }
    14: { default: number[]; '24__under'?: number[]; senior?: number[] }
  }
  'drop-in-(dj|live-music)-dance-party': {
    dj: { default: number[]; '24__under'?: number[]; senior?: number[] }
    'live-music': { default: number[]; '24__under'?: number[]; senior?: number[] }
  }
}

// FIXME: hardcoding series pricing based on user role
// There is no easy way to query product pricing rules.
// { [role]: [1x price, 2x same series price, price w/ multi-month program] } }
const pricingTable: PricingTableType = {
  '(3|4|5|6|7|8|9|13|14)-week-series': {
    3: { default: [54, 40.5, 27] },
    4: { default: [72, 54, 36] },
    5: { default: [90, 67.5, 45] },
    // Multi-month programs
    // Swing into the Seaons has 6, 7, or 8 Wednesdays
    // Fall into swing has 7 or 8 Wednesdays
    // Resolution swing has 8 or 9 Wednesdays
    // Summer Swing has 13 or 14 Wednesdays
    6: { default: [81] },
    7: { default: [94] },
    8: { default: [108] },
    9: { default: [120] },
    13: { default: [144] },
    14: { default: [144] },
  },
  'drop-in-(dj|live-music)-dance-party': {
    dj: { default: [20, 15] },
    'live-music': { default: [25, 17.5] },
  },
}

const getEventFromProductIdOrVariationId = (productId: string, items: DbItemsType) => {
  for (const item of Object.values(items)) {
    if (item.event && item.product) {
      if (productId === item.product.id) {
        return item.event
      }

      const variations = item.product.product_variations || {}
      for (const pId of Object.keys(variations)) {
        if (productId === pId) return item.event
      }
    }
  }

  return null
}

const getProductPrice = (
  productId: string,
  userRoles: Array<checkin.userRoleEnum>,
  productsInfo: ProductsInfoType,
  items: DbItemsType
) => {
  const product = getProductOrVariationFromId(productId, items) as
    | DbItemProductType
    | DbItemProductVariationType
  const simplePricesByRole = (role: checkin.userRoleEnum) => Number(product.prices[role])
  let pricesByRole = simplePricesByRole

  // check if product has corresponding event
  const event = getEventFromProductIdOrVariationId(productId, items)
  if (event) {
    // get corresponding pricing table key
    let pricingTableKey: keyof PricingTableType | 'empty' = 'empty'
    let pricingTableSubKey: keyof (typeof pricingTable)[keyof PricingTableType]
    let pricingTableValue: (typeof pricingTable)[keyof PricingTableType] | undefined
    let pricingTableForProduct:
      | (typeof pricingTableValue)[keyof typeof pricingTableValue]
      | undefined
    let isMultiMonthProgram = false

    const attributes = product.attributes.filter(
      attr => 'option' in attr && !attr.option.match(/not/i)
    )
    for (const [regex, value] of Object.entries(pricingTable)) {
      for (const attr of attributes) {
        if ('option' in attr) {
          const weekSeriesMatches = attr.option.match(/(\d+)-week-series/i)
          if (weekSeriesMatches && Number(weekSeriesMatches[1]) > 5) {
            isMultiMonthProgram = true
          }
          const matches = attr.option.match(new RegExp(regex, 'i'))
          if (matches && matches[1]) {
            pricingTableSubKey = matches[1] as keyof typeof value
            pricingTableKey = regex as keyof PricingTableType
            pricingTableValue = value
            pricingTableForProduct = value[pricingTableSubKey]
            break
          }
        }
      }
      if (pricingTableKey !== 'empty') {
        break
      }
    }

    // count how many products match the pricing table key
    // (to calculate pricing for multiple series)
    let count = 0
    let hasMultiMonthProgram = false
    if (pricingTableKey !== 'empty' && pricingTableForProduct) {
      Object.keys(productsInfo).forEach(pId => {
        const p = getProductOrVariationFromId(pId, items) as
          | DbItemProductType
          | DbItemProductVariationType

        const attributes = p.attributes.filter(
          attr => 'option' in attr && !attr.option.match(/not/i)
        )
        for (const attr of attributes) {
          if ('option' in attr) {
            const weekSeriesMatches = attr.option.match(/(\d+)-week-series/i)
            if (weekSeriesMatches && Number(weekSeriesMatches[1]) > 5) {
              hasMultiMonthProgram = true
            }
            const matches = attr.option.match(new RegExp(pricingTableKey, 'i'))
            if (matches) {
              // check subkey matches
              if (matches[1] === pricingTableSubKey) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                count += productsInfo[pId].quantity!
                break
              }
            }
          }
        }
      })

      if (count > 0) {
        pricesByRole = role => {
          let p = simplePricesByRole(role)
          const pricingForRole = pricingTableForProduct[role] as number[]
          if (pricingForRole) {
            // The same price applies for 2+ products.
            const index = hasMultiMonthProgram && !isMultiMonthProgram ? 2 : Math.min(count - 1, 1)
            p = pricingForRole[index < pricingForRole.length ? index : 0]
            if (p === undefined || Number.isNaN(p)) {
              p = simplePricesByRole(role)
            }
          }
          return p
        }
      }
    }
  }

  const price = userRoles
    .concat(userRoleEnum.default)
    .reduce((p, role) => Math.min(p, pricesByRole(role)), pricesByRole(userRoleEnum.default))

  return price
}

export const getValuesProductsInfo = (
  // eslint-disable-next-line default-param-last
  orderValues: string[] = [],
  items: DbItemsType,
  userRoles: Array<checkin.userRoleEnum> = []
) => {
  const productsInfo: ProductsInfoType = {}
  const productsInfoByValue = {} as { [id: string]: { text: string } }
  let orderTotal = 0

  orderValues.forEach(value => {
    let productId = value
    const matches = value.match(/^((-?\d+) x|#(\d+) -) (\d+)$/)
    let quantity = 0
    if (matches) {
      productId = matches[4]
      if (matches[1].match(/^#/)) {
        quantity += 1
      } else {
        quantity += Number(matches[2])
      }
    } else {
      quantity += 1
    }

    const product = getProductOrVariationFromId(productId, items)
    if (product) {
      productsInfo[productId] || (productsInfo[productId] = {} as ProductInfoType)
      productsInfo[productId].quantity || (productsInfo[productId].quantity = 0)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      productsInfo[productId].quantity! += quantity
      productsInfo[productId].name = stripItemName(product.name, { keepAttributes: true })
      productsInfoByValue[value] || (productsInfoByValue[value] = {} as { text: string })
      productsInfoByValue[
        value
      ].text = `${productsInfo[productId].quantity} x ${productsInfo[productId].name}`
    } else {
      error('No product found for ID', productId, 'within', items)
    }
  })

  const updatedOrderValues: Array<string> = []
  Object.keys(productsInfo).forEach(productId => {
    productsInfo[productId].price = getProductPrice(productId, userRoles, productsInfo, items)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    orderTotal += productsInfo[productId].price! * productsInfo[productId].quantity!
    updatedOrderValues.push(`${productsInfo[productId].quantity} x ${productId}`)
  })

  return {
    info: productsInfo,
    infoByValue: productsInfoByValue,
    orderValues: updatedOrderValues.sort(),
    orderTotal,
  }
}

export const getRegistrantNamesForLocationIndex = (
  reg: RegistrantType,
  create: boolean,
  locationIndex: number
) => getRegistrantNames(reg).filter((name, index) => create || index === locationIndex)

export const getDropDownInfo = (
  reg: RegistrantType,
  name: string,
  orderIds: Array<string>,
  create: boolean,
  values: { [id: string]: Array<string> },
  items: DbItemsType,
  allItems: DbItemsType
) => {
  const getDropDownDefaultValue = (orderId: string, noDropDownValues = false) =>
    getRegistrantDropdownValues(
      reg.items,
      reg,
      name,
      orderId,
      create,
      noDropDownValues ? {} : values,
      allItems
    )
      .map(({ key, text, value }) => value)
      .sort()
  const getDropDownOptions = (orderId: string) =>
    getRegistrantDropdownOptions(reg.items, reg, name, orderId, allItems, items, create, values)

  const dropDownDefaultValue = {} as { [id: string]: Array<string> }
  const dropDownDefaultValueAll = {} as { [id: string]: Array<string> }
  const dropDownOptions = {} as {
    [id: string]: Array<{ key: string; text: string; value: string }>
  }
  const dropDownKey = {} as { [id: string]: string }
  orderIds.forEach(orderId => {
    dropDownDefaultValue[orderId] = getDropDownDefaultValue(orderId)
    dropDownDefaultValueAll[orderId] = getDropDownDefaultValue(orderId, true)
    dropDownOptions[orderId] = getDropDownOptions(orderId)
    dropDownKey[orderId] = JSON.stringify(reg.items)
      .concat(JSON.stringify(dropDownDefaultValueAll[orderId])) // FIXME: do we need the 'All' version here?
      .concat(JSON.stringify(dropDownOptions[orderId]))
  })

  return {
    dropDownDefaultValue,
    dropDownDefaultValueAll,
    dropDownOptions,
    dropDownKey,
  }
}

export const updateTopMenuTitle = (fn: (c: React.ReactNode) => void, title: string) =>
  fn(<div className="top-menu-title">{heDecode(title)}</div>)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce<P extends Array<any>, T>(
  fn: (...params: P) => T,
  wait: number,
  immediate?: boolean
) {
  let timeout: NodeJS.Timeout | undefined
  let e: T

  return (...args: P) => {
    if (immediate && !timeout) e = fn(...args)

    timeout && clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = undefined
      if (!immediate) {
        e = fn(...args)
      }
    }, wait)

    return e
  }
}

export const getOrderProductMetadata = (
  productId: string,
  productInfo: ProductInfoType,
  items: DbItemsType
): wcOrderProductMetaDataType => {
  const metaData: wcOrderProductMetaDataType = {
    _event_product_title: productInfo.name,
  }

  const event = getEventFromProductIdOrVariationId(productId, items)
  if (event) {
    const product = getProductOrVariationFromId(productId, items) as
      | DbItemProductType
      | DbItemProductVariationType
    const weeksFromAttrs = getWeeksFromAttrs(product)
    const eventStartDates = weeksFromAttrs.length
      ? event.start_dates.slice(-weeksFromAttrs[0])
      : event.start_dates

    const eventStartDatetimes = eventStartDates.map(dateTimeString => dayjs(dateTimeString).unix())
    const dates = eventStartDatetimes
      .map((datetime, idx) =>
        dayjs(datetime * 1000).format(
          idx === eventStartDatetimes.length - 1 ? 'MMM D, YYYY' : 'MMM D'
        )
      )
      .join(' | ')
    const dateField: 'Dates' | 'Date' = `Date${eventStartDatetimes.length === 1 ? '' : 's'}`

    // picking the current event ID should be okay for class series too
    metaData._event_id = `${event.id}`
    metaData._event_start_datetimes = eventStartDatetimes
    metaData[dateField] = dates
  }

  return metaData
}

// try to get the number of weeks from product variation attributes
export const getWeeksFromAttrs = (product: DbItemProductType | DbItemProductVariationType) =>
  product.attributes.reduce((acc, attr) => {
    'option' in attr &&
      attr.option?.match(/(\d+)-week/i)?.forEach((match, idx) => {
        if (idx === 1) acc.push(Number(match))
      })
    return acc
  }, [] as number[])
