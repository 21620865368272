// config.wnh.ts
//
// Configuration options
//

// Server config production mode
const production =
  process.env.CONFIG_MODE !== undefined
    ? process.env.CONFIG_MODE === 'production'
    : process.env.NODE_ENV === 'production'

const noSSL = process.env.NO_SSL !== undefined ? process.env.NO_SSL === 'true' : false
if (!noSSL && process.env.REACT_APP_UNENCRYPTED_BACKEND === 'true') {
  throw new Error(
    `noSSL=true and REACT_APP_UNENCRYPTED_BACKEND=${process.env.REACT_APP_UNENCRYPTED_BACKEND} are incompatible, please fix your config.`
  ) // eslint-disable-line max-len
}

const defaultSslPath = './server/src/keys'

const clientHostPort = production ? null : process.env.CLIENT_HOST_PORT || '3000'

const config: configType = {
  version: '6.2.1',
  appName: 'WNH Checkin App',
  production,

  // enable/disable debug messages
  debug: process.env.DEBUG !== undefined ? process.env.DEBUG === 'true' : !production,
  self_test: process.env.SELF_TEST !== undefined ? process.env.SELF_TEST === 'true' : !production,

  no_ssr: process.env.NO_SSR !== undefined ? process.env.NO_SSR === 'true' : false,
  node_stream: process.env.NODE_STREAM !== undefined ? process.env.NODE_STREAM === 'true' : false,
  pipe_stream: process.env.PIPE_STREAM !== undefined ? process.env.PIPE_STREAM === 'true' : false,

  // ssl server config
  no_ssl: noSSL,
  ssl_email: 'ssl@wednesdaynighthop.com',
  https_force: process.env.FORCE_HTTPS !== undefined ? process.env.FORCE_HTTPS !== 'false' : true,
  domains: [process.env.DOMAIN || 'checkin.wednesdaynighthop.com'],
  // client
  host: process.env.HOST || 'localhost',
  host_port: clientHostPort,
  // server
  http_port: process.env.PORT_HTTP || '8888',
  https_port: process.env.PORT_HTTPS || '8443',
  ssl_path: process.env.SSL_PATH || defaultSslPath,
  use_proxy: process.env.USE_PROXY !== undefined ? process.env.USE_PROXY === 'true' : false,

  // cloudflare
  cloudflare_email: process.env.CLOUDFLARE_EMAIL || 'cloudflare@wednesdaynighthop.com',
  cloudflare_api_key: process.env.CLOUDFLARE_API_KEY || 'cd8cfab2b2f0e7e839ecde27a8238457c2be8',

  // wordpress server
  url: process.env.WC_URL || 'https://wednesdaynighthop.com',

  // oauth
  oauthapi_path: '/oauth',
  oauth_authorize_path: '/oauth/authorize',
  oauth_port: clientHostPort,
  // eslint-disable-next-line no-nested-ternary,max-len
  oauth_client_id: production
    ? 'HOIOjxg8KP4ECzEaTGABd3FAf1M3erZCG4AdUP0K'
    : process.env.CLIENT_HOST_PORT === '8443'
    ? 'd70CWNS8FTaPTVG03kZCtTETutiu7Y'
    : 'HgqEJm4LcSDXbrQO4IRkibK5pfDCxVHgLnrzAr3I',
  // eslint-disable-next-line no-nested-ternary,max-len
  oauth_client_secret: production
    ? 'KIGXCqOjtTRbODzGsd8ce2wHbBoRQrlEE8JAx4xU'
    : process.env.CLIENT_HOST_PORT === '8443'
    ? 'Uk5UX1HP9nJjdCeTDidCUHvzA03Tua'
    : 'DoA1sAV0QUfCM4Db46fbYNjyIieu5Jj28Jvhd7Ve',
  oauth_token_path: '/oauth/token',
  oauth_grant_type: 'authorization_code',
  oauth_state_secret: '@9&2^48*',
  oauth_redirect_path: '/oauth_redirect/',
  oauth_user_regexp: '^(checkin_app_(user|teachers)|gilles)$',

  // WooCommerce REST API
  wcapi_version: 'wc/v3',
  wcapi_path: 'wnhapi',
  webhook_port: clientHostPort,
  webhook_api_path: 'api/webhook',
  webhook_types: ['order'],
  wcapi_users: {
    default: {
      consumerKey: process.env.WCAPI_KEY || 'ck_9f5b4509d864d72c34acca9a155726ef729ae618',
      consumerSecret: process.env.WCAPI_SECRET || 'cs_b2df578b9e997030ed31ee55b4b278504acefb1a',
    },
    // '24__under': {
    //   consumerKey: 'ck_4e065d0ffc65ec0729cc031cfab5eb59a6f6634e',
    //   consumerSecret: 'cs_555512b8e6f7f1ba244fe5a5ddaf936b5e45dad2',
    // },
    // senior: {
    //   consumerKey: 'ck_1065a931be0fa89568c7178b0f3acd7ac1fc0f9f',
    //   consumerSecret: 'cs_38a53ec9a2c0e7ca763bb8e460f41eb3078aef54',
    // },
  } as {
    [key in checkin.userRoleEnum]: {
      consumerKey: string
      consumerSecret: string
    }
  },

  // The Events Calendar REST API
  hasEvents: true,
  tecapi_version: 'tribe/events/v1',
  tecapi_path: 'wnhapi',
  // currently uses oauth_token below for authentication

  // WordPress REST API
  wpapi_version: 'wp/v2',
  wpapi_path: 'wnhapi',
  // Auth token using application password (generated with: echo -n "USERNAME:PASSWORD" | base64)
  // user: checkin_app_user
  auth_token: 'Y2hlY2tpbl9hcHBfdXNlcjpUQnZpIGlLUnEgeGdJRyAzNzZlIFdRRWYgYkVMYg==',

  // Wordpress GraphQL
  wpgraphql_endpoint: 'wnhgraphql',

  // Redis
  redis_port: process.env.REDIS_PORT || (production ? '6380' : '6379'),
  redis_host: process.env.REDIS_SERVER || (production ? 'redis' : '127.0.0.1'),
  redis_prefix: 'namespace:wnhcheckinapp-',

  // session
  secure_cookie: production,
  samesite_cookie: 'strict',

  // users
  users: {
    test_user: 210,
  },

  // orders
  orders: {
    statuses: ['completed', 'processing', 'on-hold', 'pending'],
    // statuses for order lookup
    lookupStatuses: ['completed', 'processing', 'on-hold', 'pending', 'refunded'],
  },

  // products
  products: {
    // we always want to load the community fund donation product, so that we do not have to do a refresh if a customer has donated
    always_load_slugs: ['community-fund-donation'],
    test_product: 10226,
  },

  // store credit
  support_store_credit: true,
}

export default config
